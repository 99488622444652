export interface Configuration {
  identity: string | null
  version: string | null
  runningVersion: string | null
  corporation: string | null
  store: string | null
  user: string | null
  language: string | null
  startupURL: string | null
  periodicInterval: string | null
  periodicDisabledFrom: string | null
  periodicDisabledTo: string | null
  shutdownAt: string | null
  dailyUpdatedAt: string | null
  hourlyUpdatedAt: string | null
  storeUpdatedAt: string | null
  mdzaikoUpdatedAt: string | null
  baseURLstore: string | null
  baseURLmdzaiko: string | null
  enableIdentifiableAccess: string | null
  enableErrorReporting: string | null
  enableLaunchPWA: string | null
  parallelismOfImageDownloading: string | null
  notifyImageDownloadingProgress: string | null
}

export const configurationKeys: Array<keyof Configuration> = [
  'identity',
  'version',
  'runningVersion',
  'corporation',
  'store',
  'user',
  'language',
  'startupURL',
  'periodicInterval',
  'periodicDisabledFrom',
  'periodicDisabledTo',
  'shutdownAt',
  'dailyUpdatedAt',
  'hourlyUpdatedAt',
  'storeUpdatedAt',
  'mdzaikoUpdatedAt',
  'baseURLstore',
  'baseURLmdzaiko',
  'enableIdentifiableAccess',
  'enableErrorReporting',
  'enableLaunchPWA',
  'parallelismOfImageDownloading',
  'notifyImageDownloadingProgress'
]
