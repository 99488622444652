import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { clearRecords } from '../modules/domain'

const ClearRecordsAction: FC = () => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(clearRecords())
  }, [dispatch])

  return (
    <IconButton color="inherit" onClick={handleClick}>
      <Delete />
    </IconButton>
  )
}

export default ClearRecordsAction
