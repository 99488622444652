import { LinearProgress, makeStyles, Theme } from '@material-ui/core'
import Button, { ButtonProps } from '@material-ui/core/Button'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    background: 'transparent',
    opacity: 0.3
  }
}))

interface Props extends ButtonProps {
  progress: boolean
}

const ProgressButton: FC<Props> = props => {
  const { color, children, disabled, progress, ...rest } = props
  const classes = useStyles()

  return (
    <Button color={color} disabled={disabled || progress} {...rest}>
      {children}
      {progress && (
        <LinearProgress
          className={classes.progress}
          color={color === 'secondary' ? 'secondary' : 'primary'}
          variant="query"
        />
      )}
    </Button>
  )
}

export default ProgressButton
