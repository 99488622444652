import { Grid, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { Record } from '../../entities/record'
import { ArrowLeft, ArrowRight } from './arrow'

const useStyles = makeStyles({
  w100: {
    width: '100%'
  }
})

const HTTPSequence: FC<Record> = props => {
  const { type } = props
  const classes = useStyles()

  switch (type) {
    case 'server-request':
      return (
        <Grid container={true}>
          <Grid className={classes.w100} item={true} xs={6}>
            <ArrowRight />
          </Grid>
          <Grid className={classes.w100} item={true} xs={6} />
        </Grid>
      )

    case 'server-response':
      return (
        <Grid container={true}>
          <Grid className={classes.w100} item={true} xs={6}>
            <ArrowLeft />
          </Grid>
          <Grid className={classes.w100} item={true} xs={6} />
        </Grid>
      )

    case 'client-request':
      return (
        <Grid container={true}>
          <Grid className={classes.w100} item={true} xs={6} />
          <Grid className={classes.w100} item={true} xs={6}>
            <ArrowRight />
          </Grid>
        </Grid>
      )

    case 'client-response':
      return (
        <Grid container={true}>
          <Grid className={classes.w100} item={true} xs={6} />
          <Grid className={classes.w100} item={true} xs={6}>
            <ArrowLeft />
          </Grid>
        </Grid>
      )

    case 'message':
    case 'warning':
    case 'error':
    case 'generic':
      return (
        <Grid className={classes.w100} container={true}>
          <Grid className={classes.w100} item={true} xs={1} />
          <Grid className={classes.w100} item={true} xs={1} />
        </Grid>
      )
  }
}

export default HTTPSequence
