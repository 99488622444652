import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { yellow } from '@material-ui/core/colors'
import React, { FC } from 'react'
import { Record } from '../../entities/record'
import HTTPMethodView from './HTTPMethodView'
import HTTPStatusView from './HTTPStatusView'

const useStyles = makeStyles((theme: Theme) => ({
  w100: {
    width: '100%'
  },
  url: {
    padding: theme.spacing(0, 1),
    flexGrow: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  protocol: {
    color: theme.palette.text.hint
  },
  domain: {},
  path: {
    color: theme.palette.primary.main
  },
  query: {
    color: theme.palette.secondary.main
  },
  hidden: {
    visibility: 'hidden'
  },

  message: {},
  warning: {
    color: yellow[500]
  },
  error: {
    color: theme.palette.error.main
  },
  generic: {},
  location: {
    marginLeft: theme.spacing(0.5),
    fontSize: '0.8em',
    color: theme.palette.text.hint
  }
}))

const HTTPView: FC<Record> = props => {
  const classes = useStyles()

  switch (props.type) {
    case 'server-request':
    case 'client-request': {
      const url = new URL(props.path)
      return (
        <Grid container={true} alignItems="center" wrap="nowrap">
          <HTTPMethodView method={props.method} />
          <Typography className={classes.url} component="p" variant="body1">
            <span className={classes.protocol}>{`${url.protocol}//`}</span>
            <span className={classes.domain}>{url.hostname}</span>
            <span className={classes.path}>{url.pathname}</span>
            <span className={classes.query}>{url.search}</span>
          </Typography>
          <HTTPStatusView className={classes.hidden} status={200} />
        </Grid>
      )
    }

    case 'server-response':
    case 'client-response': {
      const url = new URL(props.path)
      return (
        <Grid container={true} alignItems="center" wrap="nowrap">
          <HTTPMethodView method={props.method} />
          <Typography className={classes.url} component="p" variant="body1">
            <span className={classes.protocol}>{`${url.protocol}//`}</span>
            <span className={classes.domain}>{url.hostname}</span>
            <span className={classes.path}>{url.pathname}</span>
            <span className={classes.query}>{url.search}</span>
          </Typography>
          <HTTPStatusView status={props.status} />
        </Grid>
      )
    }

    case 'message':
    case 'warning':
    case 'error':
    case 'generic':
      return (
        <Typography className={classes[props.type]}>
          {props.content}
          <span className={classes.location}>
            at {props.file}:{props.line}
          </span>
        </Typography>
      )
  }
}

export default HTTPView
