import { makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  common: {
    color: theme.palette.text.disabled
  },
  difference: {
    color: theme.palette.secondary.light
  }
}))

interface Props {
  current: string
  previous: string
}

const DateTimeDifference: FC<Props> = props => {
  const { current, previous } = props
  const classes = useStyles()

  const currentTime = current.substring(11)
  const previousTime = previous.substring(11)
  const i = firstMismatch(currentTime, previousTime)

  return (
    <Typography variant="caption">
      <span className={classes.common}>{currentTime.substring(0, i)}</span>
      <span className={classes.difference}>{currentTime.substring(i)}</span>
    </Typography>
  )
}

export default DateTimeDifference

function firstMismatch(s1: string, s2: string): number {
  for (let i = 0; i < s1.length; ++i) {
    if (s1[i] !== s2[i]) {
      return i
    }
  }
  return s1.length
}
