import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC } from 'react'

const useStyles = makeStyles({
  200: {
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: '#71AFF8'
  },
  300: {
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: '#6FC995'
  },
  400: {
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: '#E74F47'
  },
  500: {
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: '#E74F47'
  },
  0: {
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: '#DDDDDD'
  }
})

interface Props {
  className?: string
  status: number
}

const HTTPStatusView: FC<Props> = props => {
  const { className, status } = props
  const classes = useStyles()

  const statusKey = (() => {
    if (200 <= status && status < 300) {
      return 200
    } else if (300 <= status && status < 400) {
      return 300
    } else if (400 <= status && status < 500) {
      return 400
    } else if (500 <= status && status < 600) {
      return 500
    } else {
      return 0
    }
  })()

  return (
    <Typography className={clsx(className, classes[statusKey])} align="right">
      {status}
    </Typography>
  )
}

export default HTTPStatusView
