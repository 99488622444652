import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  line: {
    width: '100%',
    height: 2
  },
  lineRight: {
    background: theme.palette.primary.dark
  },
  lineLeft: {
    background: theme.palette.primary.light
  },
  arrow: {
    width: 12,
    height: 12,
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 6
  },
  arrowRight: {
    borderLeftColor: theme.palette.primary.dark
  },
  arrowLeft: {
    width: 12,
    height: 12,
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 6,
    borderRightColor: theme.palette.primary.light
  }
}))

export const ArrowRight: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={clsx(classes.line, classes.lineRight)} />
      <div className={clsx(classes.arrow, classes.arrowRight)} />
    </div>
  )
}

export const ArrowLeft: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={clsx(classes.arrow, classes.arrowLeft)} />
      <div className={clsx(classes.line, classes.lineLeft)} />
    </div>
  )
}
