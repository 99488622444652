import produce from 'immer'
import {
  ActionType,
  createAsyncAction,
  createReducer,
  createStandardAction
} from 'typesafe-actions'
import { Configuration } from '../entities/configuration'
import { FSNode } from '../entities/fs'
import { Record } from '../entities/record'

export interface DomainState {
  version: null | string
  configuration: null | Configuration
  children: null | 'loading' | FSNode[] | Error
  records: Record[]
  automaticFetchRecords: boolean
  discardingPeriodicLogs: boolean
}

const initialState: DomainState = {
  version: null,
  configuration: null,
  children: null,
  records: [],
  automaticFetchRecords: false,
  discardingPeriodicLogs: true
}

export const getVersion = createAsyncAction(
  'GET_VERSION_REQUEST',
  'GET_VERSION_SUCCESS',
  'GET_VERSION_FAILURE'
)<void, string, Error>()

export const getConfiguration = createAsyncAction(
  'GET_CONFIGURATION_REQUEST',
  'GET_CONFIGURATION_SUCCESS',
  'GET_CONFIGURATION_FAILURE'
)<void, Configuration, Error>()

export const listChildren = createAsyncAction(
  'LIST_CHILDREN_REQUEST',
  'LIST_CHILDREN_SUCCESS',
  'LIST_CHILDREN_FAILURE'
)<void, FSNode[], Error>()

export const deleteItem = createAsyncAction(
  'DELETE_ITEM_REQUEST',
  'DELETE_ITEM_SUCCESS',
  'DELETE_ITEM_FAILURE'
)<FSNode, FSNode, Error>()

export const factoryReset = createAsyncAction(
  'FACTORY_RESET_REQUEST',
  'FACTORY_RESET_SUCCESS',
  'FACTORY_RESET_FAILURE'
)<void, void, Error>()

export const fetchRecords = createAsyncAction(
  'FETCH_RECORDS_REQUEST',
  'FETCH_RECORDS_SUCCESS',
  'FETCH_RECORDS_FAILURE'
)<void, Record[], Error>()

export const fetchRecordsAutomatically = createStandardAction(
  'FETCH_RECORDS_AUTOMATICALLY'
)<boolean>()

export const changeDiscardingPeriodicLogs = createStandardAction(
  'CHANGE_DISCARDING_PERIODIC_LOGS'
)<boolean>()

export const clearRecords = createStandardAction('CLEAR_RECORDS')<void>()

export const domainReducer = createReducer<
  DomainState,
  ActionType<
    | typeof getVersion
    | typeof getConfiguration
    | typeof listChildren
    | typeof deleteItem
    | typeof factoryReset
    | typeof fetchRecords
    | typeof fetchRecordsAutomatically
    | typeof changeDiscardingPeriodicLogs
    | typeof clearRecords
  >
>(initialState)
  .handleAction(getVersion.request, state =>
    produce(state, draft => {
      draft.version = null
    })
  )
  .handleAction(getVersion.success, (state, { payload: version }) =>
    produce(state, draft => {
      draft.version = version
    })
  )
  .handleAction(getConfiguration.request, state =>
    produce(state, draft => {
      draft.configuration = null
    })
  )
  .handleAction(getConfiguration.success, (state, { payload: configuration }) =>
    produce(state, draft => {
      draft.configuration = configuration
    })
  )
  .handleAction(listChildren.request, state =>
    produce(state, draft => {
      draft.children = 'loading'
    })
  )
  .handleAction(listChildren.success, (state, { payload: children }) =>
    produce(state, draft => {
      draft.children = children
    })
  )
  .handleAction(listChildren.failure, (state, { payload: error }) =>
    produce(state, draft => {
      draft.children = error
    })
  )
  .handleAction(deleteItem.request, (state, { payload: target }) =>
    produce(state, draft => {
      if (!Array.isArray(draft.children)) {
        return
      }
      draft.children = draft.children.map(node => {
        if (node.path === target.path) {
          return { ...node, deleting: true }
        } else {
          return node
        }
      })
    })
  )
  .handleAction(deleteItem.success, (state, { payload: target }) =>
    produce(state, draft => {
      if (!Array.isArray(draft.children)) {
        return
      }
      draft.children = draft.children.filter(node => node.path !== target.path)
    })
  )
  .handleAction(factoryReset.request, state =>
    produce(state, draft => {
      draft.children = 'loading'
    })
  )
  .handleAction(fetchRecords.success, (state, { payload: records }) =>
    produce(state, draft => {
      draft.records.push(...records)
    })
  )
  .handleAction(fetchRecordsAutomatically, (state, { payload: value }) =>
    produce(state, draft => {
      draft.automaticFetchRecords = value
    })
  )
  .handleAction(changeDiscardingPeriodicLogs, (state, { payload: value }) =>
    produce(state, draft => {
      draft.discardingPeriodicLogs = value
    })
  )
  .handleAction(clearRecords, state =>
    produce(state, draft => {
      draft.records = []
    })
  )
