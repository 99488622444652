import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core'
import { Folder } from '@material-ui/icons'
import { basename } from 'path'
import React, { FC } from 'react'
import { Directory } from '../../entities/fs'
import useLink from '../useLink'
import DeleteItemAction from './DeleteItemAction'

interface Props {
  item: Directory
}

const ExplorerItemDirectory: FC<Props> = props => {
  const {
    item,
    item: { path, deleting = false }
  } = props

  return (
    <ListItem
      button={true}
      component={useLink(path)}
      disabled={deleting}
      divider={true}
    >
      <ListItemIcon>
        <Folder />
      </ListItemIcon>
      <ListItemText primary={basename(path)} />
      <ListItemSecondaryAction>
        <DeleteItemAction target={item} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default ExplorerItemDirectory
