import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles
} from '@material-ui/core'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { Settings } from '@material-ui/icons'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeServerURL } from '../modules/application'
import { createStateSelectors } from '../store'
import FactoryResetAction from './FactoryResetAction'
import useBoolean from './useBoolean'

const useStyles = makeStyles({
  stretch: {
    flexGrow: 1
  }
})

const selectors = createStateSelectors({
  serverURL: state => state.application.serverURL
})

const SettingsDialog: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [open, openDialog, closeDialog] = useBoolean(false)

  const serverURL = useSelector(selectors.serverURL)
  const [localServerURL, setLocalServerURL] = useState(serverURL)
  const localServerURLRef = useRef(serverURL)
  useEffect(() => {
    localServerURLRef.current = localServerURL
  }, [localServerURL])

  type TextFieldChange = Required<TextFieldProps>['onChange']
  const handleChangeServerURL = useCallback<TextFieldChange>(event => {
    setLocalServerURL(event.target.value)
  }, [])

  const commitChange = useCallback(() => {
    dispatch(changeServerURL(localServerURLRef.current))
    closeDialog()
  }, [dispatch, closeDialog])

  return (
    <>
      <IconButton color="inherit" onClick={openDialog}>
        <Settings />
      </IconButton>
      <Dialog fullWidth={true} open={open} onClose={closeDialog}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent dividers={true}>
          <TextField
            autoFocus={true}
            fullWidth={true}
            label="server URL"
            placeholder="http://localhost"
            value={localServerURL}
            onChange={handleChangeServerURL}
          />
        </DialogContent>
        <DialogActions>
          <FactoryResetAction />
          <div className={classes.stretch} />
          <Button onClick={closeDialog}>Cancel</Button>
          <Button color="primary" onClick={commitChange}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SettingsDialog
