import { IconButton } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'
import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { listChildren } from '../modules/domain'

const ReloadAction: FC = () => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(listChildren.request())
  }, [dispatch])

  return (
    <IconButton color="inherit" onClick={handleClick}>
      <Refresh />
    </IconButton>
  )
}

export default ReloadAction
