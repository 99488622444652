import Omit from '@m5d215/ts-omit'
import axios from 'axios'
import { join } from 'path'
import { Configuration } from './configuration'
import { Directory, FSNode } from './fs'
import { Record } from './record'

export async function listChildren(
  baseURL: string,
  directory: Directory
): Promise<FSNode[]> {
  const url = trimTrailingSlash(join('/fs', directory.path))
  type T = Omit<FSNode, 'type'>
  const { data } = await axios.get<T[]>(url, { baseURL })
  return data.map<FSNode>(({ path, size }) => {
    return {
      type: path.includes('.') ? 'file' : 'directory',
      path: join(directory.path, path),
      size
    }
  })
}

export async function getVersion(baseURL: string): Promise<string> {
  interface R {
    version: string
  }
  const { data } = await axios.post<R>('/init', {}, { baseURL })
  await new Promise(resolve => setTimeout(resolve, 3000))
  return data.version
}

export async function deleteItem(
  baseURL: string,
  target: FSNode
): Promise<void> {
  const url = trimTrailingSlash(join('/fs', target.path))
  await axios.delete(url, { baseURL })
}

export async function factoryReset(baseURL: string): Promise<void> {
  await axios.post('/factory-reset', undefined, { baseURL })
}

export async function fetchRecords(baseURL: string): Promise<Record[]> {
  const { data } = await axios.get<Record[]>('/log', { baseURL })
  return data.filter(record => {
    if (
      (record.type === 'server-request' || record.type === 'server-response') &&
      record.path.match(/^https?:\/\/localhost\/log/)
    ) {
      return false
    }
    return true
  })
}

export async function getConfiguration(
  baseURL: string
): Promise<Configuration> {
  const { data } = await axios.post<Configuration>('/init', {}, { baseURL })
  return data
}

function trimTrailingSlash(path: string): string {
  if (path.length !== 0 && path[path.length - 1] === '/') {
    return path.substring(0, path.length - 1)
  } else {
    return path
  }
}
