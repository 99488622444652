import {
  connectRouter,
  routerMiddleware,
  RouterState
} from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { Selector } from 'reselect'
import { applicationReducer, ApplicationState } from './modules/application'
import applicationSaga from './modules/application-saga'
import { domainReducer, DomainState } from './modules/domain'
import domainSaga from './modules/domain-saga'

export interface State {
  application: ApplicationState
  domain: DomainState
  router: RouterState
}

export function createStateSelectors<
  T extends { [key: string]: Selector<State, unknown> }
>(selectors: T): T {
  return selectors
}

export const browserHistory = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

export default createStore(
  combineReducers<State>({
    application: applicationReducer,
    domain: domainReducer,
    router: connectRouter(browserHistory)
  }),
  applyMiddleware(
    createLogger({ collapsed: true, diff: true }),
    routerMiddleware(browserHistory),
    sagaMiddleware
  )
)

sagaMiddleware.run(applicationSaga)
sagaMiddleware.run(domainSaga)
