import { makeStyles } from '@material-ui/core'
import React, { FC, useCallback, useMemo, useRef } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { MeasuredComponentProps, withContentRect } from 'react-measure'

const useStyles = makeStyles({
  root: {
    height: '100%'
  }
})

interface Props {
  autoScroll?: boolean
}

const Scrollable: FC<Props & MeasuredComponentProps> = props => {
  const { children, measureRef, contentRect, autoScroll } = props
  const classes = useStyles()

  const { height } = contentRect.bounds || { height: 0 }
  const style = useMemo<React.CSSProperties>(() => ({ height }), [height])

  const scrollbars = useRef<Scrollbars | null>(null)
  const scrollAutomatically = useRef(true)
  const previousScrollHeight = useRef<number | null>(null)

  type OnUpdate = Required<React.ComponentProps<typeof Scrollbars>>['onUpdate']
  const handleUpdate = useCallback<OnUpdate>(
    values => {
      if (scrollbars.current === null || !autoScroll) {
        return
      }

      const { clientHeight, scrollHeight, scrollTop } = values
      if (
        scrollAutomatically.current &&
        previousScrollHeight.current !== scrollHeight
      ) {
        const { view } = (scrollbars.current as unknown) as {
          view: HTMLElement
        }
        view.scrollTo({
          top: scrollHeight,
          behavior: 'smooth'
        })
      }
      scrollAutomatically.current =
        Math.round(clientHeight + scrollTop - scrollHeight) === 0
      previousScrollHeight.current = scrollHeight
    },
    [autoScroll]
  )

  return (
    <div ref={measureRef} className={classes.root}>
      <Scrollbars ref={scrollbars} style={style} onUpdate={handleUpdate}>
        {children}
      </Scrollbars>
    </div>
  )
}

export default withContentRect('bounds')(Scrollable)
