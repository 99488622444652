import produce from 'immer'
import {
  ActionType,
  createReducer,
  createStandardAction
} from 'typesafe-actions'

export interface ApplicationState {
  serverURL: string
}

const initialState: ApplicationState = {
  serverURL: localStorage.getItem('serverURL') || 'http://localhost'
}

export const changeServerURL = createStandardAction('CHANGE_SERVER_URL')<
  string
>()

export const applicationReducer = createReducer<
  ApplicationState,
  ActionType<typeof changeServerURL>
>(initialState).handleAction(changeServerURL, (state, { payload: serverURL }) =>
  produce(state, draft => {
    draft.serverURL = serverURL
  })
)
