import { FormControlLabel, Switch } from '@material-ui/core'
import { SwitchProps } from '@material-ui/core/Switch'
import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeDiscardingPeriodicLogs } from '../modules/domain'
import { createStateSelectors } from '../store'

const selectors = createStateSelectors({
  discardingPeriodicLogs: state => state.domain.discardingPeriodicLogs
})

const DiscardingPeriodicLogsAction: FC = () => {
  const dispatch = useDispatch()
  const discardingPeriodicLogs = useSelector(selectors.discardingPeriodicLogs)

  const handleChange = useCallback<Required<SwitchProps>['onChange']>(
    (_, checked) => {
      dispatch(changeDiscardingPeriodicLogs(checked))
    },
    [dispatch]
  )

  return (
    <FormControlLabel
      control={
        <Switch checked={discardingPeriodicLogs} onChange={handleChange} />
      }
      label="周期的なログを破棄"
    />
  )
}

export default DiscardingPeriodicLogsAction
