import { FormControlLabel, Switch } from '@material-ui/core'
import { SwitchProps } from '@material-ui/core/Switch'
import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRecordsAutomatically } from '../modules/domain'
import { createStateSelectors } from '../store'

const selectors = createStateSelectors({
  automaticFetchRecords: state => state.domain.automaticFetchRecords
})

const AutomaticFetchRecordsAction: FC = () => {
  const dispatch = useDispatch()
  const automaticFetchRecords = useSelector(selectors.automaticFetchRecords)

  const handleChange = useCallback<Required<SwitchProps>['onChange']>(
    (_, checked) => {
      dispatch(fetchRecordsAutomatically(checked))
    },
    [dispatch]
  )

  return (
    <FormControlLabel
      control={
        <Switch checked={automaticFetchRecords} onChange={handleChange} />
      }
      label="自動更新"
    />
  )
}

export default AutomaticFetchRecordsAction
