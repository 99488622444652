import Omit from '@m5d215/ts-omit'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme
} from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import clsx from 'clsx'
import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { factoryReset } from '../modules/domain'
import useBoolean from './useBoolean'

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.error.light
  }
}))

type Props = Omit<ButtonProps, 'children' | 'onClick'>

const FactoryResetAction: FC<Props> = props => {
  const { className, ...buttonProps } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const [open, openDialog, closeDialog] = useBoolean(false)

  const handleFactoryReset = useCallback(() => {
    dispatch(factoryReset.request())
    closeDialog()
  }, [dispatch, closeDialog])

  return (
    <>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>システム初期化</DialogTitle>
        <DialogContent>
          <DialogContentText>
            アプリのストレージを初期化します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>キャンセル</Button>
          <Button className={classes.warning} onClick={handleFactoryReset}>
            初期化
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        className={clsx(classes.warning, className)}
        onClick={openDialog}
        {...buttonProps}
      >
        システム初期化
      </Button>
    </>
  )
}

export default FactoryResetAction
