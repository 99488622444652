import Omit from '@m5d215/ts-omit'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

type Props = Omit<LinkProps, 'innerRef' | 'to'>

export default function useLink(to: string): React.ComponentType<Props> {
  // eslint-disable-next-line react/display-name
  return React.forwardRef<HTMLAnchorElement, Props>((props, ref) => (
    <Link innerRef={ref} to={to} {...props} />
  ))
}
