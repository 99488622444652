import { SagaIterator } from 'redux-saga'
import { put, takeLatest } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import { changeServerURL } from './application'
import { listChildren } from './domain'

export default function* applicationSaga(): SagaIterator {
  yield takeLatest(changeServerURL, handleChangeServerURL)
}

function* handleChangeServerURL(
  action: ActionType<typeof changeServerURL>
): SagaIterator {
  const { payload: serverURL } = action
  localStorage.setItem('serverURL', serverURL)

  yield put(listChildren.request())
}
