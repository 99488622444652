import { List, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { createStateSelectors } from '../../store'
import ExplorerItem from './ExplorerItem'

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    margin: theme.spacing(1, 2)
  }
}))

const selectors = createStateSelectors({
  children: state => state.domain.children
})

const Explorer: FC = () => {
  const classes = useStyles()
  const children = useSelector(selectors.children)

  if (children === null) {
    return <></>
  }

  if (children === 'loading') {
    return <></>
  }

  if (Array.isArray(children)) {
    return (
      <List>
        {children.map(child => (
          <ExplorerItem key={child.path} item={child} />
        ))}
      </List>
    )
  }

  return (
    <Typography className={classes.error} component="p" variant="body1">
      {children.message}
    </Typography>
  )
}

export default Explorer
