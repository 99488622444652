import { dirname } from 'path'

export interface File {
  type: 'file'
  path: string
  size?: number
  deleting?: true
}

export interface Directory {
  type: 'directory'
  path: string
  size?: number
  deleting?: true
}

export type FSNode = File | Directory

export function pathComponents(directory: Directory): Directory[] {
  let { path } = directory
  const components: Directory[] = []

  while (path !== '/') {
    components.unshift({ type: 'directory', path })
    path = dirname(path)
  }
  components.unshift({ type: 'directory', path: '/' })

  return components
}
