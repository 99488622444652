import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { SagaIterator } from 'redux-saga'
import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import * as client from '../entities/client'
import { Directory } from '../entities/fs'
import { Record } from '../entities/record'
import { State } from '../store'
import {
  deleteItem,
  factoryReset,
  fetchRecords,
  fetchRecordsAutomatically,
  getConfiguration,
  getVersion,
  listChildren
} from './domain'

export default function* domainSaga(): SagaIterator {
  yield takeLatest(LOCATION_CHANGE, handleLocationChange)
  yield takeLatest(getVersion.request, handleGetVersion)
  yield takeLatest(getConfiguration.request, handleGetConfiguration)
  yield takeLatest(listChildren.request, handleListChildren)
  yield takeEvery(deleteItem.request, handleDeleteItem)
  yield takeLatest(factoryReset.request, handleFactoryReset)
  yield takeLatest(fetchRecords.request, handleFetchRecords)
  yield takeLatest(fetchRecordsAutomatically, handleFetchRecordsAutomatically)
}

function* handleLocationChange(_: LocationChangeAction): SagaIterator {
  yield put(listChildren.request())
}

function* handleGetVersion(
  _: ActionType<typeof getVersion.request>
): SagaIterator {
  const {
    application: { serverURL }
  }: State = yield select()

  try {
    const version = yield call(client.getVersion, serverURL)
    yield put(getVersion.success(version))
  } catch (error) {
    yield put(getVersion.failure(error))
  }
}

function* handleGetConfiguration(
  _: ActionType<typeof getConfiguration.request>
): SagaIterator {
  const {
    application: { serverURL }
  }: State = yield select()

  try {
    const configuration = yield call(client.getConfiguration, serverURL)
    yield put(getConfiguration.success(configuration))
  } catch (error) {
    yield put(getConfiguration.failure(error))
  }
}

function* handleListChildren(
  _: ActionType<typeof listChildren.request>
): SagaIterator {
  const {
    application: { serverURL },
    router: {
      location: { pathname }
    }
  }: State = yield select()

  const current: Directory = {
    type: 'directory',
    path: pathname
  }

  try {
    const children = yield call(client.listChildren, serverURL, current)
    yield put(listChildren.success(children))
  } catch (error) {
    yield put(listChildren.failure(error))
  }
}

function* handleDeleteItem(
  action: ActionType<typeof deleteItem.request>
): SagaIterator {
  const { payload: target } = action
  const {
    application: { serverURL }
  }: State = yield select()

  try {
    yield call(client.deleteItem, serverURL, target)
    yield put(deleteItem.success(target))
  } catch (error) {
    yield put(deleteItem.failure(error))
  }
}

function* handleFactoryReset(
  _: ActionType<typeof factoryReset.request>
): SagaIterator {
  const {
    application: { serverURL }
  }: State = yield select()

  try {
    yield call(client.factoryReset, serverURL)
    yield put(factoryReset.success())
    yield put(listChildren.request())
  } catch (error) {
    yield put(factoryReset.failure(error))
  }
}

function* handleFetchRecords(
  _: ActionType<typeof fetchRecords.request>
): SagaIterator {
  const {
    application: { serverURL },
    domain: { discardingPeriodicLogs }
  }: State = yield select()

  try {
    const children: Record[] = yield call(client.fetchRecords, serverURL)
    const records = children.filter(record => {
      if (!discardingPeriodicLogs) {
        return true
      }
      if (
        (record.type === 'server-request' ||
          record.type === 'server-response') &&
        record.path.match(/^https?:\/\/localhost\/renew\/status/)
      ) {
        return false
      }
      return true
    })
    yield put(fetchRecords.success(records))
  } catch (error) {
    yield put(fetchRecords.failure(error))
  }
}

function* handleFetchRecordsAutomatically(
  action: ActionType<typeof fetchRecordsAutomatically>
): SagaIterator {
  const { payload: automaticFetchRecords } = action

  if (!automaticFetchRecords) {
    return
  }

  while (true) {
    yield put(fetchRecords.request())
    yield delay(1000)
  }
}
