import { TableCell, TableRow } from '@material-ui/core'
import React, { FC } from 'react'

interface Props {
  name: string
  value: string | null
}

const ConfigurationEditorItem: FC<Props> = props => {
  const { name, value } = props

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{value === null ? 'null' : value}</TableCell>
    </TableRow>
  )
}

export default ConfigurationEditorItem
