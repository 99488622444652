import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Theme
} from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { FSNode } from '../../entities/fs'
import { deleteItem } from '../../modules/domain'
import ProgressButton from '../ProgressButton'
import useBoolean from '../useBoolean'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.error.main
  }
}))

interface Props {
  className?: string
  target: FSNode
}

const DeleteItemAction: FC<Props> = props => {
  const {
    className,
    target,
    target: { deleting = false }
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const [open, openDialog, closeDialog] = useBoolean(false)

  const handleDeleteItem = useCallback(() => {
    dispatch(deleteItem.request(target))
    closeDialog()
  }, [target, dispatch, closeDialog])

  return (
    <>
      <ProgressButton
        className={clsx(classes.button, className)}
        progress={deleting}
        onClick={openDialog}
      >
        Delete
      </ProgressButton>
      <Dialog fullWidth={true} open={open} onClose={closeDialog}>
        <DialogContent>
          <DialogContentText>
            この操作は元に戻すことができません。本当に削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>キャンセル</Button>
          <Button className={classes.button} onClick={handleDeleteItem}>
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteItemAction
