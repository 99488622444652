import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { createStateSelectors } from '../store'
import ConfigurationEditor from './ConfigurationEditor'
import ProgressButton from './ProgressButton'
import useBoolean from './useBoolean'

const selectors = createStateSelectors({
  version: state => state.domain.version
})

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    flexGrow: 1
  },
  close: {
    marginLeft: theme.spacing(1)
  },
  content: {
    padding: 0,
    paddingTop: theme.spacing(2),
    width: '100%',
    height: '100%'
  }
}))

const ConfigurationDialog: FC = () => {
  const classes = useStyles()
  const version = useSelector(selectors.version)
  const [open, openDialog, closeDialog] = useBoolean(false)

  return (
    <>
      <ProgressButton
        color="default"
        progress={version === null}
        onClick={openDialog}
      >
        {version || 'version'}
      </ProgressButton>
      <Dialog
        className={classes.root}
        fullWidth={true}
        open={open}
        onClose={closeDialog}
      >
        <DialogTitle
          className={classes.titleContainer}
          disableTypography={true}
        >
          <Typography className={classes.title} component="h2" variant="h6">
            UserDefaults
          </Typography>
          <IconButton className={classes.close} onClick={closeDialog}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content} dividers={true}>
          <ConfigurationEditor />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ConfigurationDialog
