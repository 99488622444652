import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'typeface-roboto'
import App from './App'
import { getVersion } from './modules/domain'
import * as serviceWorker from './serviceWorker'
import store, { browserHistory } from './store'
import theme from './theme'

store.dispatch(getVersion.request())

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={browserHistory}>
        <CssBaseline />
        <App />
      </ConnectedRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
)

serviceWorker.register()
