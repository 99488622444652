import {
  AppBar,
  LinearProgress,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { createStateSelectors } from '../store'
import ConfigurationDialog from './ConfigurationDialog'
import RecordViewDialog from './RecordViewDialog'
import ReloadAction from './ReloadAction'
import SettingsDialog from './SettingsDialog'

const useStyles = makeStyles({
  title: {
    flexGrow: 1
  },
  loading: {
    visibility: 'visible'
  },
  loaded: {
    visibility: 'collapse'
  }
})

const selectors = createStateSelectors({
  children: state => state.domain.children
})

const Header: FC = () => {
  const classes = useStyles()
  const children = useSelector(selectors.children)
  const loading = children === 'loading'

  return (
    <AppBar color="primary" position="static">
      <LinearProgress
        className={loading ? classes.loading : classes.loaded}
        color="secondary"
        variant="query"
      />
      <Toolbar variant="dense">
        <Typography
          className={classes.title}
          color="inherit"
          component="h1"
          variant="h6"
        >
          iOS Document Storage
        </Typography>
        <ConfigurationDialog />
        <RecordViewDialog />
        <ReloadAction />
        <SettingsDialog />
      </Toolbar>
    </AppBar>
  )
}

export default Header
