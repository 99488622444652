import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { configurationKeys } from '../../entities/configuration'
import { getConfiguration } from '../../modules/domain'
import { createStateSelectors } from '../../store'
import ConfigurationEditorItem from './ConfigurationEditorItem'

const selectors = createStateSelectors({
  configuration: state => state.domain.configuration
})

const ConfigurationEditor: FC = () => {
  const configuration = useSelector(selectors.configuration)
  const dispatch = useDispatch()

  useEffect(() => {
    if (configuration === null) {
      dispatch(getConfiguration.request())
    }
  }, [configuration, dispatch])

  if (configuration === null) {
    return <LinearProgress variant="query" />
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>キー</TableCell>
          <TableCell>値</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {configurationKeys.map(key => (
          <ConfigurationEditorItem
            key={key}
            name={key}
            value={configuration[key]}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default ConfigurationEditor
