import React, { FC } from 'react'
import { FSNode } from '../../entities/fs'
import ExplorerItemDirectory from './ExplorerItemDirectory'
import ExplorerItemFile from './ExplorerItemFile'

interface Props {
  item: FSNode
}

const ExplorerItem: FC<Props> = props => {
  const { item } = props

  switch (item.type) {
    case 'directory':
      return <ExplorerItemDirectory item={item} />
    case 'file':
      return <ExplorerItemFile item={item} />
  }
}

export default ExplorerItem
