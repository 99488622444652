import { makeStyles, Theme } from '@material-ui/core'
import React, { FC } from 'react'
import Explorer from './components/Explorer'
import ExplorerBreadcrumbs from './components/ExplorerBreadcrumbs'
import Header from './components/Header'
import Scrollable from './components/Scrollable'

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumb: {
    margin: theme.spacing(1, 2)
  }
}))

const App: FC = () => {
  const classes = useStyles()

  return (
    <>
      <Header />
      <div className={classes.breadcrumb}>
        <ExplorerBreadcrumbs />
      </div>
      <Scrollable>
        <Explorer />
      </Scrollable>
    </>
  )
}

export default App
