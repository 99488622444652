import { makeStyles, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { HTTPMethod } from '../../entities/record'

const useStyles = makeStyles<{}, {}, HTTPMethod>({
  GET: {
    minWidth: 50,
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: '#71AFF8'
  },
  POST: {
    minWidth: 50,
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: '#6FC995'
  },
  PUT: {
    minWidth: 50,
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: '#F0A44A'
  },
  PATCH: {
    minWidth: 50,
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: '#F0A44A'
  },
  DELETE: {
    minWidth: 50,
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: '#E74F47'
  },
  OPTIONS: {
    minWidth: 50,
    fontSize: '0.75em',
    fontWeight: 'bold',
    color: '#DDDDDD'
  }
})

interface Props {
  method: HTTPMethod
}

const HTTPMethodView: FC<Props> = props => {
  const { method } = props
  const classes = useStyles()

  return (
    <Typography className={classes[method]} align="right">
      {method}
    </Typography>
  )
}

export default HTTPMethodView
