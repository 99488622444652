import { Breadcrumbs } from '@material-ui/core'
import { NavigateNext } from '@material-ui/icons'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { pathComponents } from '../../entities/fs'
import { createStateSelectors } from '../../store'
import ExplorerBreadcrumbsItem from './ExplorerBreadcrumbsItem'

const selectors = createStateSelectors({
  pathname: state => state.router.location.pathname
})

const ExplorerBreadcrumbs: FC = () => {
  const pathname = useSelector(selectors.pathname) || '/'

  const components = pathComponents({ type: 'directory', path: pathname })

  return (
    <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
      {components.map(({ path }, i) => (
        <ExplorerBreadcrumbsItem
          key={path}
          disabled={i === components.length - 1}
          path={path}
        />
      ))}
    </Breadcrumbs>
  )
}

export default ExplorerBreadcrumbs
