import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { FC } from 'react'
import AutomaticFetchRecordsAction from './AutomaticFetchRecordsAction'
import ClearRecordsAction from './ClearRecordsAction'
import DiscardingPeriodicLogsAction from './DiscardingPeriodicLogsAction'
import FetchRecordsAction from './FetchRecordsAction'
import RecordView from './RecordView/RecordView'
import useBoolean from './useBoolean'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    flexGrow: 1
  },
  close: {
    marginLeft: theme.spacing(1)
  },
  content: {
    padding: 0,
    paddingTop: theme.spacing(2),
    width: '100%',
    height: '100%'
  }
}))

const RecordViewDialog: FC = () => {
  const classes = useStyles()
  const [open, openDialog, closeDialog] = useBoolean(false)

  return (
    <>
      <Button color="inherit" onClick={openDialog}>
        ログ
      </Button>
      <Dialog
        className={classes.root}
        fullScreen={true}
        open={open}
        onClose={closeDialog}
      >
        <DialogTitle
          className={classes.titleContainer}
          disableTypography={true}
        >
          <Typography className={classes.title} component="h2" variant="h6">
            APP Log
          </Typography>
          <DiscardingPeriodicLogsAction />
          <AutomaticFetchRecordsAction />
          <FetchRecordsAction />
          <ClearRecordsAction />
          <IconButton className={classes.close} onClick={closeDialog}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content} dividers={true}>
          <RecordView />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default RecordViewDialog
