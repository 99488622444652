import { Button } from '@material-ui/core'
import { basename } from 'path'
import React, { FC } from 'react'
import useLink from '../useLink'

interface Props {
  disabled: boolean
  path: string
}

const ExplorerBreadcrumbsItem: FC<Props> = props => {
  const { disabled, path } = props

  return (
    <Button color="inherit" component={useLink(path)} disabled={disabled}>
      {basename(path) || 'DOCUMENT'}
    </Button>
  )
}

export default ExplorerBreadcrumbsItem
