import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { createStateSelectors } from '../../store'
import Scrollable from '../Scrollable'
import DateTimeDifference from './DateTimeDifference'
import HTTPSequence from './HTTPSequence'
import HTTPView from './HTTPView'

const useStyles = makeStyles((theme: Theme) => ({
  wh100: {
    width: '100%',
    height: '100%'
  },
  w100: {
    width: '100%'
  },

  root: {
    position: 'relative'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  static: {
    padding: theme.spacing(1, 3),
    background: theme.palette.background.paper
  },
  scroll: {
    padding: theme.spacing(0, 3),
    paddingTop: 32
  },
  adornment: {
    pointerEvents: 'none'
  },

  participant: {
    marginLeft: -12
  },
  verticalLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider
  },
  item: {
    padding: theme.spacing(1, 0),
    width: '100%',
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1
  },
  http: {
    padding: theme.spacing(0, 2)
  }
}))

const selectors = createStateSelectors({
  records: state => state.domain.records
})

const RecordView: FC = () => {
  const classes = useStyles()
  const records = useSelector(selectors.records)

  return (
    <div className={clsx(classes.wh100, classes.root)}>
      <div className={clsx(classes.wh100, classes.overlay)}>
        <div className={clsx(classes.wh100, classes.scroll)}>
          <Grid className={classes.wh100} container={true}>
            <Grid className={classes.wh100} item={true} xs={1} />
            <Grid className={classes.wh100} item={true} xs={1}>
              <div className={classes.verticalLine} />
            </Grid>
            <Grid className={classes.wh100} item={true} xs={1}>
              <div className={classes.verticalLine} />
            </Grid>
            <Grid className={classes.wh100} item={true} xs={1}>
              <div className={classes.verticalLine} />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={clsx(classes.wh100, classes.overlay)}>
        <Scrollable autoScroll={true}>
          <div className={clsx(classes.wh100, classes.scroll)}>
            {records.map((record, i) => (
              <Grid
                key={i}
                className={classes.item}
                container={true}
                alignItems="center"
              >
                <Grid className={classes.w100} item={true} xs={1}>
                  <DateTimeDifference
                    current={record.timestamp}
                    previous={
                      i === 0 ? record.timestamp : records[i - 1].timestamp
                    }
                  />
                </Grid>
                <Grid className={classes.w100} item={true} xs={2}>
                  <HTTPSequence {...record} />
                </Grid>
                <Grid className={classes.w100} item={true} xs={9}>
                  <div className={clsx(classes.w100, classes.http)}>
                    <HTTPView {...record} />
                  </div>
                </Grid>
              </Grid>
            ))}
          </div>
        </Scrollable>
      </div>

      <div className={clsx(classes.w100, classes.overlay, classes.adornment)}>
        <div className={clsx(classes.w100, classes.static)}>
          <Grid
            className={classes.w100}
            container={true}
            alignItems="flex-start"
          >
            <Grid className={classes.w100} item={true} xs={1} />
            <Grid className={classes.w100} item={true} xs={1}>
              <Typography className={classes.participant} variant="caption">
                PWA
              </Typography>
            </Grid>
            <Grid className={classes.w100} item={true} xs={1}>
              <Typography className={classes.participant} variant="caption">
                APP
              </Typography>
            </Grid>
            <Grid className={classes.w100} item={true} xs={1}>
              <Typography className={classes.participant} variant="caption">
                API
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default RecordView
