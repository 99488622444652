import { createMuiTheme } from '@material-ui/core'
import { blue, purple } from '@material-ui/core/colors'

export default createMuiTheme({
  palette: {
    primary: blue,
    secondary: purple,
    type: 'dark'
  }
})
