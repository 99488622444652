import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme
} from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import filesize from 'filesize'
import { basename } from 'path'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { File } from '../../entities/fs'
import { createStateSelectors } from '../../store'
import DeleteItemAction from './DeleteItemAction'

const useStyles = makeStyles((theme: Theme) => ({
  action: {
    marginLeft: theme.spacing(1)
  }
}))

const selectors = createStateSelectors({
  serverURL: state => state.application.serverURL
})

interface Props {
  item: File
}

const ExplorerItemFile: FC<Props> = props => {
  const {
    item,
    item: { path, size, deleting = false }
  } = props
  const classes = useStyles()
  const serverURL = useSelector(selectors.serverURL)

  return (
    <ListItem disabled={deleting} divider={true}>
      <ListItemIcon>
        <FileCopy />
      </ListItemIcon>
      <ListItemText
        primary={basename(path)}
        secondary={size && filesize(size)}
      />
      <ListItemSecondaryAction>
        <Button
          className={classes.action}
          color="primary"
          disabled={deleting}
          href={`${serverURL}/fs${path}`}
          target="_blank"
        >
          open
        </Button>
        <DeleteItemAction className={classes.action} target={item} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default ExplorerItemFile
